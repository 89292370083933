import React, { Fragment, useState, useReducer } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { GlobalSet } from '../../global/GlobalProvider';
import { Link ,useHistory} from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import axios from "axios";
import { useEffect } from 'react';
//** Import Image */
import profile from "../../../../images/profile/user.jpg";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const initialState = false;
const reducer = (state, action) =>{
	switch (action.type){
		case 'sendMessage':
			return { ...state, sendMessage: !state.sendMessage }		
		case 'postModal':
			return { ...state, post: !state.post }
		case 'linkModal':
			return { ...state, link: !state.link }		
		case 'cameraModal':
			return { ...state, camera: !state.camera }		
		case 'replyModal':
			return { ...state, reply: !state.reply }
		default:
			return state	
	}	
}


  const AppProfile = () => {
  const { CurrencySymbol } = GlobalSet();
  const { t } = useTranslation();
  const [user, setUser] = useState([]);
    useEffect(()=>{
    	const auth_token = localStorage.getItem('auth_token');
    	const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
    	if (auth_token !== null) {
    		 axios.post('/apps-profile',data)
       	 .then(response=>{
           setUser(response.data)
           console.log(response.data)
           
			     })
			     .catch(error=>{
			       console.log(error)
			   })
    	}

    },[])

	let Email;
	let Username;
	let mobile_No;
	let name;
	let balance;
	let bonus_balance;
	let total_deposit;
	let total_Income;
	let total_withdraw;
	let affiliate_earning;
	if (user == "") {
		console.log(user);
	}else{

		Email=user.data.email;
		name=user.data.name;
		mobile_No=user.data.mobile_No;
		Username=user.data.username;
		balance=user.data.balance;
		bonus_balance=user.data.bonus_balance;
		total_deposit=user.data.total_deposit;
		total_Income=user.data.total_Income;
		total_withdraw=user.data.total_withdraw;
		affiliate_earning=user.data.affiliate_earning;

	}
		
	const options = {
	  settings: {
	    overlayColor: "#000000",
	  },
	};

	const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Fragment>
						<div className="col-xl-12">
							<div className="row text-black">
								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
													  <path  d="M27,14V7a1,1,0,0,0-1-1H6A1,1,0,0,0,5,7v7a3,3,0,0,0-3,3v8a1,1,0,0,0,2,0V24H28v1a1,1,0,0,0,2,0V17A3,3,0,0,0,27,14ZM7,8H25v6H24V12a2,2,0,0,0-2-2H19a2,2,0,0,0-2,2v2H15V12a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2v2H7Zm12,6V12h3v2Zm-9,0V12h3v2ZM4,17a1,1,0,0,1,1-1H27a1,1,0,0,1,1,1v5H4Z" transform="translate(-2 -6)" fill="var(--primary)"/>
													</svg>
												</span>
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(balance).toFixed(4)) }</h4>
													<p className="mb-0 text-nowrap">{t('pro_pg_curr_bal')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
													  <path  d="M27,14V7a1,1,0,0,0-1-1H6A1,1,0,0,0,5,7v7a3,3,0,0,0-3,3v8a1,1,0,0,0,2,0V24H28v1a1,1,0,0,0,2,0V17A3,3,0,0,0,27,14ZM7,8H25v6H24V12a2,2,0,0,0-2-2H19a2,2,0,0,0-2,2v2H15V12a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2v2H7Zm12,6V12h3v2Zm-9,0V12h3v2ZM4,17a1,1,0,0,1,1-1H27a1,1,0,0,1,1,1v5H4Z" transform="translate(-2 -6)" fill="var(--primary)"/>
													</svg>
												</span>
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(total_deposit).toFixed(4)) }</h4>
													<p className="mb-0 text-nowrap ">{t('pro_pg_tot_dep')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
													  <path  data-name="Path 1957" d="M129.035,178.842v2.8a5.6,5.6,0,0,0,5.6,5.6h14a5.6,5.6,0,0,0,5.6-5.6v-16.8a5.6,5.6,0,0,0-5.6-5.6h-14a5.6,5.6,0,0,0-5.6,5.6v2.8a1.4,1.4,0,0,0,2.8,0v-2.8a2.8,2.8,0,0,1,2.8-2.8h14a2.8,2.8,0,0,1,2.8,2.8v16.8a2.8,2.8,0,0,1-2.8,2.8h-14a2.8,2.8,0,0,1-2.8-2.8v-2.8a1.4,1.4,0,0,0-2.8,0Zm10.62-7-1.81-1.809a1.4,1.4,0,1,1,1.98-1.981l4.2,4.2a1.4,1.4,0,0,1,0,1.981l-4.2,4.2a1.4,1.4,0,1,1-1.98-1.981l1.81-1.81h-12.02a1.4,1.4,0,1,1,0-2.8Z" transform="translate(-126.235 -159.242)" fill="var(--primary)" fill-rule="evenodd"/>
													</svg>
												</span>
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(total_Income).toFixed(4)) }</h4>
													<p className="mb-0">{t('pro_pg_tot_inc')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span>
													<svg id="_009-log-out" data-name="009-log-out" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
													  <path  data-name="Path 1957" d="M151.435,178.842v2.8a5.6,5.6,0,0,1-5.6,5.6h-14a5.6,5.6,0,0,1-5.6-5.6v-16.8a5.6,5.6,0,0,1,5.6-5.6h14a5.6,5.6,0,0,1,5.6,5.6v2.8a1.4,1.4,0,0,1-2.8,0v-2.8a2.8,2.8,0,0,0-2.8-2.8h-14a2.8,2.8,0,0,0-2.8,2.8v16.8a2.8,2.8,0,0,0,2.8,2.8h14a2.8,2.8,0,0,0,2.8-2.8v-2.8a1.4,1.4,0,0,1,2.8,0Zm-10.62-7,1.81-1.809a1.4,1.4,0,1,0-1.98-1.981l-4.2,4.2a1.4,1.4,0,0,0,0,1.981l4.2,4.2a1.4,1.4,0,1,0,1.98-1.981l-1.81-1.81h12.02a1.4,1.4,0,1,0,0-2.8Z" transform="translate(-126.235 -159.242)" fill="var(--primary)" fill-rule="evenodd"/>
													</svg>

												</span>
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(total_withdraw).toFixed(4)) }</h4>
													<p className="mb-0">{t('pro_pg_tot_wit')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



     <div className="row">
        <div className="col-xl-4">

		</div>	
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
					<Tab.Container defaultActiveKey='About'>					
						<Nav as='ul' className="nav nav-tabs mb-4">
							<Nav.Item as='li'i className="nav-item">
								<Nav.Link to="#about-me"  eventKey='About'>{t('pro_pg_ab_me')}</Nav.Link>
							</Nav.Item>
						</Nav>
						<Tab.Content>

							<Tab.Pane id="about-me" eventKey='About'>
								<div className="profile-personal-info text-black">
									<h4 className="text-primary mb-4">
										Personal Information
									</h4>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500"> {t('pro_pg_name')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{name}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500"> {t('pro_pg_username')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{Username}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">{t('pro_pg_mob_num')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{mobile_No}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">{t('pro_pg_email')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{Email}</span>
										</div>
									</div>
								</div>
							</Tab.Pane>
						</Tab.Content>	
					</Tab.Container>		
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </Fragment>
  );
};

export default AppProfile;
