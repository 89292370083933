import { Card, Col, ListGroup, Row } from "react-bootstrap";
import axios from "axios";
import {Link} from 'react-router-dom';
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { GlobalSet } from '../../jsx/components/global/GlobalProvider';

const Footer = () => {
  const { t } = useTranslation();
  const { BaseUrl } = GlobalSet();
  const { RootUrl } = GlobalSet();
  var d = new Date();
  return (
    <div className="footer m-4 text-black">
      <div style={{textAlign:'center'}} className="copyright">
      <Row>
        <Col  className="col-xl-12 col-xxl-12 col-sm-12">
            <Card.Body>
            <Row>
            <div style={{marginRight: "30px"}}>
               <h4>{t('pro_footer_o_s_b')}</h4> 
               <p>{t('pro_footer_o_s_b_text_a')}</p>
               <p>{t('pro_footer_o_s_b_text_b')}</p>
            </div>
                 <Card.Header>
              <Card.Title>Our Partner</Card.Title>
            </Card.Header>
              <Col className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-4 mb-2">
                  <div className="basic-list-group">
                    <li as="ul">
                        <img 
                          src={BaseUrl+"public/basic_img/asdgsdgure.png"} 
                          alt="hover effect" 
                          className="image-hover img-fluid"
                          style={{ width: "150px" }}
                        />
                    </li>
                  </div>
              </Col>
              <Col className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-3 mb-2">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <img 
                          src={BaseUrl+"public/basic_img/dgsdagure.png"} 
                          alt="hover effect" 
                          className="image-hover img-fluid"
                          style={{ width: "150px" }}
                        />
                    </li>
                  </div>
              </Col>
              <Col className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-4 mb-2">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <img 
                          src={BaseUrl+"public/basic_img/dgasdgure.png"} 
                          alt="hover effect" 
                          className="image-hover img-fluid"
                          style={{ width: "150px" }}
                        />
                    </li>
                  </div>
              </Col>
              <Col className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-4 mb-2">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <img 
                          src={BaseUrl+"public/basic_img/evolution.png"} 
                          alt="hover effect" 
                          className="image-hover img-fluid"
                          style={{ width: "150px" }}
                        />
                    </li>
                  </div>
              </Col>
              <Col className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-4 mb-2">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <img 
                          src={BaseUrl+"public/basic_img/Spinmatic.png"} 
                          alt="hover effect" 
                          className="image-hover img-fluid"
                          style={{ width: "150px" }}
                        />
                    </li>
                  </div>
              </Col>
              <Col className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-4 mb-2">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <img 
                          src={BaseUrl+"public/basic_img/ElysiumStudios.png"} 
                          alt="hover effect" 
                          className="image-hover img-fluid"
                          style={{ width: "150px" }}
                        />
                    </li>
                  </div>
              </Col>
              <Col className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-4 mb-2">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <img 
                          src={BaseUrl+"public/basic_img/Platipus.png"} 
                          alt="hover effect" 
                          className="image-hover img-fluid"
                          style={{ width: "150px" }}
                        />
                    </li>
                  </div>
              </Col>
              <Col className="col-xl-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3 col-4 mb-2">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <img 
                          src={BaseUrl+"public/basic_img/dgdsgpture.png"} 
                          alt="hover effect" 
                          className="image-hover img-fluid"
                          style={{ width: "150px" }}
                        />
                    </li>
                  </div>
              </Col>
            </Row>
            </Card.Body>
        </Col>
      </Row>


      <Row>
        <Col  className="col-xl-6 col-xxl-6 col-sm-6">
            <Card.Header>
              <Card.Title>{t('pro_footer_information')}</Card.Title>
            </Card.Header>
            <Card.Body>

            <Row>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group">
                    <li as="ul">
                        <a href="https://agent.oddbet.io/" target="_blank" rel="noopener noreferrer">
                          <li className="mb-2 text-black">{t('pro_footer_slot_dic')}</li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href="https://affiliate.oddbet.io/" target="_blank" rel="noopener noreferrer">
                          <li className="mb-2 text-black">{t('pro_footer_aff_pro')}</li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"view-page/about-us"} target="_blank">
                         <li className="mb-2 text-black"> {t('pro_footer_about_us')}</li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"view-page/Terms-and-conditions"} target="_blank">
                         <li className="mb-2 text-black"> {t('pro_footer_trms_con')}</li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"view-page/privacy-policy"} target="_blank">
                         <li className="mb-2 text-black"> {t('pro_footer_pr_po')}</li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                         <a href="https://blog.oddbet.io/" target="_blank" rel="noopener noreferrer">
                         <li className="mb-2 text-black">Update Blog</li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"blog"} target="_blank">
                         <li className="mb-2 text-black">All Best Offer</li>
                        </a>
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <a href={RootUrl+"sportsbook"}>
                        <li className="mb-2 text-black"> {t('pro_footer_lv_gm')} </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"sportsbook?bt-path=%2Fsoccer-1"} target="_blank">
                        <li className="mb-2 text-black"> {t('pro_footer_foo_bl')} </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"sportsbook?bt-path=%2Fbasketball-2"} target="_blank">
                        <li className="mb-2 text-black"> {t('pro_footer_bask_bl')} </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"sportsbook?bt-path=%2Ftennis-5"} target="_blank">
                        <li className="mb-2 text-black"> Tennis </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"sportsbook?bt-path=%2Fesport-fifa-137"} target="_blank">
                        <li className="mb-2 text-black"> Esports </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"sportsbook?bt-path=%2Fgreyhound-58"} target="_blank">
                        <li className="mb-2 text-black"> Horse Racing </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"sportsbook?bt-path=%2Fbadminton-31"} target="_blank">
                        <li className="mb-2 text-black"> Badminton </li>
                        </a>
                    </li>
                  </div>
              </Col>
            </Row>
            </Card.Body>
        </Col>
        <Col  className="col-xl-6 col-xxl-6 col-sm-6">
            <Card.Header>
              <Card.Title>{t('pro_footer_sports')} </Card.Title>
            </Card.Header>
            <Card.Body>


            <Row>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <a href={RootUrl+"home-game/all-game"} target="_blank">
                        <li className="mb-2 text-black"> {t('pro_footer_casino')}  </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"home-game/live-casino"} target="_blank">
                        <li className="mb-2 text-black"> {t('pro_footer_lv_cas')} </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"home-game/jackpot-slots"} target="_blank">
                        <li className="mb-2 text-black"> {t('pro_footer_tv_gm')} </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"home-game/crazy-time"} target="_blank">
                        <li className="mb-2 text-black">  Crazy Time </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"home-game/crazy-time"} target="_blank">
                        <li className="mb-2 text-black"> Bingo </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"home-game/crash"} target="_blank">
                        <li className="mb-2 text-black"> Crash </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"casino-providers"} target="_blank">
                        <li className="mb-2 text-black">  Popular </li>
                        </a>
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group text-black">
                    <li as="ul">
                        <a href={RootUrl+"view-page/oddbet-betting-rules"} target="_blank">
                        <li className="mb-2 text-black"> {t('pro_footer_bt_res')} </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"view-page/betting-information"} target="_blank">
                        <li className="mb-2 text-black">  {t('pro_footer_bt_info')} </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"view-page/responsible-gaming"} target="_blank">
                        <li className="mb-2 text-black">{t('pro_footer_res_gm')} </li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"view-page/security"} target="_blank">
                        <li className="mb-2 text-black">Security</li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"view-page/disclaimer"} target="_blank">
                        <li className="mb-2 text-black">Disclaimer</li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>
                        <a href={RootUrl+"view-page/good-advice"} target="_blank">
                        <li className="mb-2 text-black">Good Advice</li>
                        </a>
                        <hr style={{ margin : "1px", marginBottom: "2px" }}/>

                        <a href={RootUrl+"view-page/entertainment"} target="_blank">
                        <li className="mb-2 text-black">Entertainment</li>
                        </a>
                    </li>
                  </div>
              </Col>
            </Row>


            </Card.Body>
        </Col>
      </Row>


      <Row>
        <Col  className="col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <Card.Header>
              <Card.Title> Alert </Card.Title>
            </Card.Header>
            <Card.Body>
            <Row>
              <Col  className="col-xl-4 col-xxl-4 col-sm-4">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                    <img 
                      src={BaseUrl+"public/basic_img/pngtree-18-icon.png"} 
                      alt="hover effect" 
                      className="img-fluid"
                      style={{ width: "60px" }}
                    />
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-4 col-xxl-4 col-sm-4">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                    <img 
                      src={BaseUrl+"public/basic_img/disclaimer.png"} 
                      alt="hover effect" 
                      className="img-fluid"
                      style={{ width: "60px" }}
                    />
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-4 col-xxl-4 col-sm-4">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                    <img 
                      src={"https://img.abyssale.com/574bfa75-c880-46be-97ae-599473818958"} 
                      alt="hover effect" 
                      className="img-fluid"
                      style={{ width: "60px" }}
                    />
                    </li>
                  </div>
              </Col>
            </Row>
            </Card.Body>
        </Col>
        <Col  className="col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <Card.Header>
              <Card.Title>Social Community</Card.Title>
            </Card.Header>
            <Card.Body>

            <Row>
              <Col  className="col-xl-2 col-xxl-2 col-sm-2">
                  <div className="basic-list-group">
                    <li as="ul">
                        <a href="https://www.instagram.com/oddbetglobal/" target="_blank" rel="noopener noreferrer">
                          <img 
                            src={BaseUrl+"public/appssource/all_icon/tg.webp"} 
                            alt="hover effect" 
                            className="img-fluid image-hover"
                            style={{ width: "40px"}}
                          />
                        </a>
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-2 col-xxl-2 col-sm-2">
                  <div className="basic-list-group">
                    <li as="ul">
                        <a href="https://www.facebook.com/oddbetlive/" target="_blank" rel="noopener noreferrer">
                          <img 
                            src={BaseUrl+"public/appssource/all_icon/fb.webp"} 
                            alt="hover effect" 
                            className="img-fluid image-hover"
                            style={{ width: "40px"}}
                          />
                        </a>
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-2 col-xxl-2 col-sm-2">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <a href="https://t.me/oddbetofficial/" target="_blank" rel="noopener noreferrer">
                          <img 
                            src={BaseUrl+"public/appssource/all_icon/tel.webp"} 
                            alt="hover effect" 
                            className="img-fluid image-hover"
                            style={{ width: "40px"}}
                          />
                        </a>
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-2 col-xxl-2 col-sm-2">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <a href="https://x.com/oddbet19972" target="_blank" rel="noopener noreferrer">
                          <img 
                            src={BaseUrl+"public/appssource/all_icon/tw.webp"} 
                            alt="hover effect" 
                            className="img-fluid image-hover"
                            style={{ width: "40px"}}
                          />
                        </a>
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-2 col-xxl-2 col-sm-2">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <a href="https://www.youtube.com/@oddbetlive/" target="_blank" rel="noopener noreferrer">
                          <img 
                            src={BaseUrl+"public/appssource/all_icon/yt.webp"} 
                            alt="hover effect" 
                            className="img-fluid image-hover"
                            style={{ width: "40px"}}
                          />
                        </a>
                    </li>
                  </div>
              </Col>
            </Row>
            </Card.Body>
        </Col>
      </Row>


        <p>
          {t('pro_footer_copyright')}
         {/* {d.getFullYear()}*/}
        </p>
      </div>
    </div>
  );
};

export default Footer;
