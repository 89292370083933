import React, { useState, useRef, useEffect , useReducer} from "react";
import { Modal } from "react-bootstrap";
import { GlobalSet } from '../../global/GlobalProvider';
import { QRCodeCanvas } from "qrcode.react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';


//** Import Image */
const BasicDatatable = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [rootDomain, setRootDomain] = useState('');
  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

/** Link */
const initialState = false;
const reducer = (state, action) =>{
  switch (action.type){
    case 'linkModal':
      return { ...state, link: !state.link }    
    default:
      return state  
  } 
}

  const { CurrencySymbol } = GlobalSet();
  const sort = 5;
  const activePag = useRef(0);
  //const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
   // chackboxFun();
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };

 /*.....income data......*/

 const [seAff, setAff] = useState([]);
 const [myRef, MyData] = useState([]);
    useEffect(()=>{
      const auth_token = localStorage.getItem('auth_token');
        const data = {
            code: id,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        }
      if (auth_token !== null) {
         axios.post('/view-my-code',data)
         .then(response=>{
           setAff(response.data.data);
           MyData(response.data.mydata)
           
           })
           .catch(error=>{
             console.log(error)
         })
      }

    },[])


//root domain select
  useEffect(() => {
    const currentUrl = window.location.href;
    const rootDomainRegex = /^(?:https?:\/\/)?(?:www\.)?([^/]+)/;
    const match = currentUrl.match(rootDomainRegex);

    if (match && match[1]) {
      setRootDomain(match[1]);
    }
  }, []); 
//end root domain select


//** qr code */
  const [url, setUrl] = useState("");
  const qrRef = useRef();
  const downloadQRCode = (e) => {
    e.preventDefault();
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    setUrl("");
  };
  const qrCodeEncoder = (e) => {
    setUrl(e.target.value);
  };

  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={"https://oddbet.io/register?ref="+seAff.referral_code}
      size={200}
      bgColor={"#000fff00"}
      level={"H"}
    />
  );

  //shere link message body
  const message_body = "This+is+an+international+betting+and+casino+website.+you+register+here.+https://oddbet.io/register?ref=" + seAff.referral_code;

  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const [isRCopySuccess, setIsRCopySuccess] = useState(false);


  const handleCopyClick = () => {
    const linkToCopy = "https://oddbet.io/register?ref=" + seAff.referral_code;
    navigator.clipboard.writeText(linkToCopy)
      .then(() => {
        setIsCopySuccess(true);
        setTimeout(() => {
          setIsCopySuccess(false);
        }, 3000); // 3000 milliseconds (3 seconds)
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };

  const handleRCopyClick = () => {
    const linkToCopy = seAff.referral_code;
    navigator.clipboard.writeText(linkToCopy)
      .then(() => {
        setIsRCopySuccess(true);
        setTimeout(() => {
          setIsRCopySuccess(false);
        }, 3000); // 3000 milliseconds (3 seconds)
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };


  const onInit = () => {
    //console.log('lightGallery has been initialized');
  };
    const options = {
      settings: {
      overlayColor: "#000000",
      },
  };
  const [state, dispatch] = useReducer(reducer, initialState);



  return (
    <div className="col-12">


            <div className="col-xl-12">
              <div className="row">

                <div className="col-xl-6 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      
                  <div className="input-group mb-3 input-primary">
                    <input
                      type="text"
                      className="form-control"
                      value={"https://oddbet.io/register?ref=" + seAff.referral_code}
                      placeholder="Recipient's username"
                    />
                      <button className="input-group-text" onClick={handleCopyClick}> {t('pro_affi_copy_link')}</button>

                      {isCopySuccess && (
                        <p>Copy successful! The link has been copied to the clipboard.</p>
                      )}

                  </div>
                      
                  <div className="input-group mb-3 input-primary">
                    <input
                      type="text"
                      className="form-control"
                      value={seAff.referral_code}
                      placeholder="Recipient's username"
                    />
                      <button className="input-group-text" onClick={handleRCopyClick}> {t('pro_affi_copy_link_cd')}</button>
                      {isRCopySuccess && (
                        <p>Copy successful! The link has been copied to the clipboard.</p>
                      )}
                  </div>





                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">{t('pro_affi_s_s_l')}</h5>
                        </div>
                        <div className="modal-body text-center">

                          <a style={{ fontSize: "32px"}} href={"https://api.whatsapp.com/send/?text="+message_body+"&type=phone_number&app_absent=0"} className="btn-social whatsapp" to="/app-profile"><i className="fab fa-whatsapp text-black" /></a>

                          <a style={{ fontSize: "32px"}} href={"https://pinterest.com/pin/create/button/?url=This+is+an+international+betting+and+casino+website.+you+register+here.+https://oddbet.io/register?ref=" + seAff.referral_code + "&type=phone_number&app_absent=0"}  className="btn-social me-1 pinterest text-black" to="/app-profile"> <i className="fab fa-pinterest" /></a>

                          <a style={{ fontSize: "32px"}} href={"https://twitter.com/intent/tweet?url="+message_body+"&type=phone_number&app_absent=0"} className="btn-social me-1 twitter" to="/app-profile"><i className="fab fa-twitter text-black" /></a>

                          <a style={{ fontSize: "32px"}} href={"https://t.me/share/url?url="+message_body+"&type=phone_number&app_absent=0"}  className="btn-social me-1 telegram" to="/app-profile"> <i className="fab fa-telegram text-black" /></a>


                          <a style={{ fontSize: "32px" }} href={"https://www.facebook.com/sharer/sharer.php?u=" + message_body + "&type=phone_number&app_absent=0"}
                            className="btn-social me-1 facebook text-black"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>

                          <a
                            style={{ fontSize: "32px" }}
                            href={`https://plus.google.com/share?url=${message_body}`}
                            className="btn-social me-1 google-plus"
                            to="/app-profile"
                          >
                            <i className="fab fa-google-plus text-black" />
                          </a>

                          <a style={{ fontSize: "32px"}}  href={"https://www.linkedin.com/shareArticle?mini=true&url=" + message_body + "&type=phone_number&app_absent=0"} className="btn-social me-1 linkedin text-black" to="/app-profile"><i className="fab fa-linkedin" /></a>
                          <a
                            style={{ fontSize: "32px" }}
                            href={`https://www.tumblr.com/widgets/share/tool/preview?shareSource=legacy&canonicalUrl=&url=${message_body}&type=phone_number&app_absent=0`}
                            className="btn-social me-1 tumblr"
                            to="/app-profile"
                          >
                            <i className="fab fa-tumblr text-black" />
                          </a>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="col-xl-6 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                          <div style={{ margin: "0px auto" }} className="qrcode__container">
                            <div className="bg-white p-3" ref={qrRef}>{qrcode}</div>
                            <div className="text-center">
                              <form onSubmit={downloadQRCode}>
                                <button type="submit">
                                  {t('pro_affi_dow_qe')}
                                </button>
                              </form>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>


            <div className="col-xl-12">
              <div className="row">

                <div className="col-xl-6 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
													<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                        </span>
                        <div className="ms-4">
                          <h4 className="mb-0 font-w600 text-black"> {seAff.total_partner} User</h4>
                          <p className="mb-0 text-black">{t('pro_affi_t_r_u')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-xl-6 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
													<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="4" width="20" height="16" rx="2"/><path d="M7 15h0M2 9.5h20"/></svg>
                        </span>
                        <div className="ms-4">
                          <h4 className="mb-0 font-w600 text-black">{CurrencySymbol + seAff.total_earning} </h4>
                          <p className="mb-0 text-black">{t('pro_affi_affi_ear')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>



      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{t('pro_affi_sp_his')}</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc" style={{ width: "177px" }}>
                     SL
                    </th>
                    <th className="sorting_asc" style={{ width: "177px" }}>
                      {t('pro_affi_name')}
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                      {t('pro_affi_username')}
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                      Total Deposit
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                      {t('pro_affi_date')}
                    </th>
                  </tr>
                </thead>

                <tbody>
                {myRef.map((incomerow, index)=>(
                  <tr key={incomerow.id} className="odd text-black" role="row">
                    <td className="sorting_1">{index + 1}</td>
                    <td className="sorting_1">{incomerow.name}</td>
                    <td>{incomerow.username}</td>
                    <td>{CurrencySymbol + incomerow.total_deposit}</td>
                    <td>{incomerow.created_at}</td>
                  </tr>
                  ))}
                </tbody>
               
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers mb-0"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to={`/view-promo-codes/${id}`}
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to={`/view-promo-codes/${id}`}
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to={`/view-promo-codes/${id}`}
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDatatable;
