/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link ,BrowserRouter as Router,Route} from "react-router-dom";
import { GlobalSet } from '../../components/global/GlobalProvider';
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import axios from "axios";


class MM extends Component {
	componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	}
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () =>{
  const { BaseUrl } = GlobalSet();
  var img_home = BaseUrl + "public/appssource/sports_icons/home.png";
  var img_balance = BaseUrl + "public/appssource/sports_icons/balance.png";
  var img_team = BaseUrl + "public/appssource/sports_icons/team.png";
  var img_security = BaseUrl + "public/appssource/sports_icons/security.png";
  var img_support_ticket = BaseUrl + "public/appssource/sports_icons/support_ticket.png";
  var img_bet_history = BaseUrl + "public/appssource/sports_icons/bet_history.png";

	  const {
  		iconHover,
  		sidebarposition,
  		headerposition,
  		sidebarLayout,
	  } = useContext(ThemeContext);
  useEffect(() => {
    //menu-toggle
      var btn = document.querySelector(".nav-control");
      btn.addEventListener("click", toggleFunc);
      var aaa = document.querySelector("#main-wrapper");
      function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

  //all-game-tournaments

     //sidebar icon Heart blast
        var handleheartBlast = document.querySelector('.heart');
        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }
        handleheartBlast.addEventListener('click', heartBlast);
	
  }, []);



 //For scroll
 const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)
    // Function to toggle menu
  const toggleMenu = () => {
    const aaa = document.querySelector("#main-wrapper");
    if (aaa) {
      aaa.classList.remove("menu-toggle");
    }
  };

  /// Path
  let path = window.location.pathname;

  /// Active menu
  let deshBoard = [
      "",
      "Game",
      "lottery",
      ],
    sports = [
     "sports/:id/:matches_slug"
      
    ]

  return (
  <div
  className={`dlabnav z-3 ${iconHover} ${
  sidebarposition.value === "fixed" &&
  sidebarLayout.value === "horizontal" &&
  headerposition.value === "static"
    ? hideOnScroll > 120
      ? "fixed"
      : ""
    : ""
  }`}
  >
  <PerfectScrollbar className="dlabnav-scroll">
  <MM className="metismenu" id="menu">

      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
        <Link to="/dashboard" onClick={toggleMenu} className="ai-icon" >
          <i><img src={img_home} alt="transaction history" style={{ width: '20px', height: '20px'}} /></i>
          <span className="nav-text">DeshBoard</span>
        </Link>
      </li>


      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
          <Link className="has-arrow" to="#" >
          <i><img src={img_balance} alt="transaction history" style={{ width: '20px', height: '20px'}} /></i>
            <span className="nav-text">Fund</span>
          </Link>
          <ul>
            <li>
               <Link to="/withdraw" onClick={toggleMenu}>Withdraw
               </Link>
            </li>         
          </ul>
      </li>

 

      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
          <Link className="has-arrow" to="#" >
            <i><img src={img_bet_history} alt="transaction history" style={{ width: '20px', height: '20px'}} /></i>
            <span className="nav-text">Report</span>
          </Link>
          <ul>
            <li>
               <Link to="/income-history" onClick={toggleMenu}>Income History
               </Link>
            </li>
            <li>
               <Link to="/transaction-history" onClick={toggleMenu}>Transaction History
               </Link>
            </li>            
          </ul>
      </li>


      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
          <Link className="has-arrow" to="#" >
           <i><img src={img_security} alt="transaction history" style={{ width: '20px', height: '20px'}} /></i>
            <span className="nav-text">Security</span>
          </Link>
          <ul>
            <li>
               <Link to="/change-password" onClick={toggleMenu}>Change password
               </Link>
            </li>
            <li>
               <Link to="/security" onClick={toggleMenu}>Security
               </Link>
            </li>            
          </ul>
      </li>


      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
          <Link className="has-arrow" to="#" >
           <i><img src={img_team} alt="transaction history" style={{ width: '20px', height: '20px'}} /></i>
            <span className="nav-text">Partner</span>
          </Link>
          <ul>
            <li>
               <Link to="/manage-promo-codes" onClick={toggleMenu}>Promo Codes
               </Link>
            </li>            
          </ul>
      </li>


      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
          <Link className="has-arrow" to="#" >
            <i><img src={img_support_ticket} alt="transaction history" style={{ width: '20px', height: '20px'}} /></i>
            <span className="nav-text">Support Center</span>
          </Link>
          <ul>
            <li>
               <Link to="/support-ticket" onClick={toggleMenu}>Support Ticket
               </Link>
            </li>           
          </ul>
      </li>



  </MM>
  <div className="dropdown header-profile2 ">
  	<div className="header-info2 text-center">
  		<div>
  			<Link to="/support-ticket" className="btn btn-md text-secondary">Contact Us</Link>
  		</div>
  	</div>
  </div>
  <div className="copyright text-black">
  	<p className="text-center"><strong>OddBet</strong> © 2024 All Rights Reserved</p>
  	<p className="fs-12 text-center">Made with <span className="heart"></span> by OddBet</p>
  </div>
  </PerfectScrollbar>
  </div>
  );
};

export default SideBar;
